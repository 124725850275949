<!--
 * @Descripttion: 整理逻辑,添加备注!!!
 * @version: 
 * @Author: Duanshuai
 * @Date: 2021-06-23 10:06:28
 * @LastEditors: ------
 * @LastEditTime: 2021-08-17 16:17:30
-->
// 客户管理详情页

<template>
  <div class="big_box">
    <TitleNav class="">
      <template #one>
        <span @click="go('home')" class="cursor"> 首页 > </span>
      </template>
      <template #two>
        <span @click="go('customerList')" class="cursor"> 客户管理 > </span>
      </template>
      <template #three>
        <span @click="go" class="cursor"> {{ info.name }}详情页 </span>
      </template>
      <template v-slot:slotCon>
        <div class="personal_detail">
          <div class="personal_detail_info base_666_color">
            <div class="pay_top">
              <img src="../image/info.png" alt="" />
              <span class="base_333_color">本人信息</span>
            </div>
            <div class="base_info">
              <span>用户姓名：{{ info.name }}</span>
              <span>用户手机号：{{ info.mobile }}</span>
            </div>
            <div class="base_info">
              <span>用户身份证：{{ info.idCard }}</span>
              <span>所在城市：{{ city || "暂无填写" }}</span>
            </div>
            <div class="base_info">
              <span>居住地址：{{ info.detailAddress || "暂无填写" }}</span>
              <!-- <span></span> -->
            </div>
          </div>
          <div
            class="personal_detail_family base_666_color"
            v-if="type == 'look'"
          >
            <div class="pay_top family_top">
              <img src="../image/family.png" alt="" />
              <span class="base_333_color">家庭成员</span>
            </div>

            <div class="family_name" v-if="clan.length">
              <div
                class="family_box cursor"
                v-for="(item, index) in clan"
                :key="index"
              >
                <div
                  class="name_tip base_white_color"
                  :class="
                    index == 0
                      ? 'backColor1'
                      : index == 1
                      ? 'backColor2'
                      : index == 2
                      ? 'backColor3'
                      : index == 4
                      ? 'backColor1'
                      : index == 5
                      ? 'backColor2'
                      : index == 6
                      ? 'backColor3'
                      : 'backColor1'
                  "
                >
                  {{
                    item.kinship == 10
                      ? "父亲"
                      : item.kinship == 11
                      ? "母亲"
                      : item.kinship == 12
                      ? "妻子"
                      : item.kinship == 13
                      ? "丈夫"
                      : item.kinship == 19
                      ? "兄妹"
                      : item.kinship == 14
                      ? "儿子"
                      : item.kinship == 15
                      ? "女儿"
                      : item.kinship == 16
                      ? "孙子"
                      : item.kinship == 17
                      ? "孙女"
                      : item.kinship == 18
                      ? "朋友"
                      : item.kinship == 20
                      ? "其他"
                      : item.kinship == 99
                      ? "自己"
                      : "其他"
                  }}
                </div>
                {{ item.name }}{{}}
                <span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; > </span>
              </div>
            </div>
            <div class="family_name" v-else>
              <div class="family_box">
                <div class="name_tip base_white_color">#家属标签#</div>
                家属信息暂未填写
                <span> &nbsp;&nbsp;&nbsp;&nbsp; > </span>
              </div>
            </div>
          </div>
          <div class="personal_detail_family base_666_color" v-else>
            <div class="pay_top family_top">
              <img v-if="type == 'clan'" src="../image/clan.png" alt="" />
              <img v-if="type == 'pay'" src="../image/pay.png" alt="" />
              <img v-if="type == 'talk'" src="../image/vioce.png" alt="" />
              <span class="base_333_color">{{
                type == "clan"
                  ? "合同记录"
                  : type == "pay"
                  ? "支付单"
                  : "访谈记录"
              }}</span>
            </div>
            <div
              class="clan_log cursor"
              v-for="(item, index) in records.slice(0, 2)"
              :key="index"
            >
              <div
                class="base_icon base_white_color"
                :class="
                  type == 'clan'
                    ? 'icon'
                    : type == 'pay'
                    ? 'pay_icon'
                    : type == 'talk'
                    ? 'talk_icon'
                    : 'icon'
                "
              >
                {{
                  type == "pay"
                    ? item.finalPayTime.substring(
                        0,
                        item.createTime.indexOf(" ")
                      )
                    : item.createTime.substring(0, item.createTime.indexOf(" "))
                }}
              </div>
              <div class="serve_info base_666_color">
                <!-- 合同列表展示 -->
                <div
                  v-if="type == 'clan'"
                  @click.self="btnClick(item.orderNo, item.storeName)"
                >
                  <p @click.self="btnClick(item.orderNo, item.storeName)">
                    服务对象：{{ item.accountFamilyName }}
                  </p>
                  <p @click.self="btnClick(item.orderNo, item.storeName)">
                    服务时间：{{
                      item.appointmentStartTime &&
                        item.appointmentStartTime.substring(
                          0,
                          item.appointmentStartTime.indexOf(" ")
                        )
                    }}~{{
                      item.appointmentEndTime &&
                        item.appointmentEndTime.substring(
                          0,
                          item.appointmentEndTime.indexOf(" ")
                        )
                    }}
                  </p>
                </div>
                <div v-if="type == 'pay'">
                  <p>支付单号: {{ item.payNo }}</p>
                  <p>服务对象: {{ item.accountName }}</p>
                  <p>支付金额: {{ item.payAmount || "0" }}元</p>
                </div>
                <div v-if="type == 'pay' && item.payStatus == '10'">
                  <button
                    class="payBtn1 base_white_color cursor"
                    @click="btnClickSupplement(item.id)"
                  >
                    补录
                  </button>
                  <button
                    class="payBtn2 base_white_color cursor"
                    @click.stop="btnClickPay(item.payNo)"
                  >
                    付款码
                  </button>
                </div>
                <div
                  v-if="type == 'talk'"
                  @click="interviewshow1(item)"
                  class="talk_con"
                >
                  <p>服务对象：{{ item.accountName }}</p>
                </div>
                <div v-if="type == 'talk'" class="right_icon">&gt;</div>
                <div v-if="type == 'clan'">&gt;</div>
                <div v-if="type == 'clan'">
                  <a-modal
                    class="clan_com"
                    :visible="visible"
                    title="合同详情"
                    @ok="handleOk"
                    width="90%"
                    @cancel="cancel"
                    :ok-button-props="{ style: { display: 'none' } }"
                    :cancel-button-props="{ style: { display: 'none' } }"
                  >
                    <Clan :clanItem="clanItem"></Clan>
                  </a-modal>
                </div>
                <div v-if="type == 'pay'">
                  <!-- 如果是支付单时  显示 -->
                  <!-- 补录弹窗 -->
                  <a-modal
                    :visible="visible2"
                    title="补录"
                    @cancel="handleOks"
                    :footer="[]"
                    :width="800"
                  >
                    <div class="tip base_666_color">
                      <a-alert
                        message="请确认此订单已由用户线下支付成功"
                        banner
                      />
                      <div class="main_pay">
                        <div class="main_pay_title">
                          <div class="mainPayTitle_con ">
                            <span
                              class="cursor"
                              :class="
                                onLine_value == 1
                                  ? 'mainPayTitle_con_backcolor'
                                  : ''
                              "
                              @click="onLine((onLine_value = 1))"
                              >在线支付</span
                            >
                            <span>|</span>

                            <span
                              class="cursor"
                              :class="
                                onLine_value == 2
                                  ? 'mainPayTitle_con_backcolor'
                                  : ''
                              "
                              @click="onLine((onLine_value = 2))"
                              >现金支付</span
                            >
                            <span>|</span>

                            <span
                              class="cursor"
                              :class="
                                onLine_value == 3
                                  ? 'mainPayTitle_con_backcolor'
                                  : ''
                              "
                              @click="onLine((onLine_value = 3))"
                              >银行转账</span
                            >
                          </div>
                        </div>
                        <div class="main_pay_body">
                          <!-- 在线支付 -->
                          <div v-if="onLine_value == 1">
                            <div class=" input_top">
                              <div class="body_left">支付方式 :</div>
                              <div class="pay_box">
                                <div
                                  class="choose_pay cursor"
                                  @click="payWay = 1"
                                >
                                  <span> </span>
                                  <span
                                    :class="payWay == 1 ? 'choose_weixin' : ''"
                                    >微信支付</span
                                  >
                                </div>
                                <div
                                  class="choose_pay cursor"
                                  @click="payWay = 2"
                                >
                                  <span></span>
                                  <span
                                    :class="payWay == 2 ? 'choose_weixin' : ''"
                                    >支付宝支付</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="input_top">
                              <div class="body_left ">支付流水号 :</div>
                              <div class="pay_box">
                                <input
                                  class="pay_running"
                                  type="text"
                                  v-model="pay_running"
                                  placeholder="请输入支付流水号"
                                />
                              </div>
                            </div>
                            <div class="input_top">
                              <div class="body_left ">支付时间 :</div>
                              <div class="pay_box">
                                <a-date-picker @change="change1" />
                              </div>
                            </div>
                          </div>
                          <div v-if="onLine_value == 2">
                            <div class=" input_top">
                              <div class="body_left">收款人姓名 :</div>
                              <div class="pay_box">
                                <input
                                  class="pay_running"
                                  type="text"
                                  v-model="pay_name"
                                  placeholder="请输入收款人姓名"
                                />
                              </div>
                            </div>
                            <div class="input_top">
                              <div class="body_left ">收款金额 :</div>
                              <div class="pay_box">
                                <input
                                  class="pay_running pay_running2"
                                  type="text"
                                  v-model="pay_num"
                                  placeholder="请输入收款金额"
                                />
                              </div>
                            </div>
                            <div class="input_top">
                              <div class="body_left ">收款日期 :</div>
                              <div class="pay_box">
                                <a-date-picker @change="change2" />
                              </div>
                            </div>
                          </div>
                          <div v-if="onLine_value == 3">
                            <div class=" input_top">
                              <div class="body_left">收款银行 :</div>
                              <div class="pay_box">
                                <!-- <input
                                  class=" pay_running pay_runningBank1"
                                  type="text"
                                  v-model="pay_bank"
                                  placeholder="请输入收款银行"
                                /> -->
                                <a-form
                                  layout="inline"
                                  :form="form"
                                  class="nurseDetail_con_item"
                                >
                                  <a-form-item>
                                    <a-select
                                      placeholder="请选择收款银行"
                                      @change="handleChange"
                                    >
                                      <a-select-option
                                        value="建设银行上海民生路支行（0025）"
                                        >建设银行上海民生路支行（0025）</a-select-option
                                      >
                                    </a-select>
                                  </a-form-item>
                                </a-form>
                              </div>
                            </div>
                            <div class="input_top">
                              <div class="body_left ">打款金额 :</div>
                              <div class="pay_box">
                                <input
                                  class="pay_running pay_running2"
                                  type="text"
                                  v-model="pay_bank_num"
                                  placeholder="请输入打款金额"
                                />
                              </div>
                            </div>
                            <div class="input_top">
                              <div class="body_left ">打款流水号 :</div>
                              <div class="pay_box">
                                <input
                                  class="pay_running "
                                  type="text"
                                  v-model="pay_bank_running"
                                  placeholder="请输入打款流水号"
                                />
                              </div>
                            </div>

                            <div class="input_top">
                              <div class="body_left ">打款时间 :</div>
                              <div class="pay_box">
                                <a-date-picker @change="change3" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="main_pay_bottom base_white_color">
                          <button
                            class="cancle cursor"
                            @click="handleOks_cancle"
                          >
                            取消
                          </button>
                          <button
                            class="submit cursor"
                            @click="subOrder(onLine_value)"
                          >
                            提交
                          </button>
                        </div>
                      </div>
                    </div>
                  </a-modal>
                  <a-modal
                    :visible="visible1"
                    title="扫码付款"
                    @cancel="handleOks"
                    :footer="[]"
                    :width="400"
                  >
                    <div class="code">
                      <div class="title">微信支付</div>
                      <vue-qrcode
                        :value="payMessage"
                        style="margin-left: 40px"
                        :options="{ width: 283 }"
                        tag="svg"
                      ></vue-qrcode>
                      <div class="title">请使用微信扫一扫</div>
                      <div class="title">扫描二维码支付</div>
                      <div class="img">
                        <img src="../image/icon_ajh.png" />
                      </div>
                    </div>
                  </a-modal>
                </div>
                <div>
                  <a-modal
                    :visible="interviewshow"
                    title="访谈详情"
                    @cancel="handleOk"
                    :footer="[]"
                    width="70%"
                  >
                    <interview :interviewlist="interviewlist"></interview>
                  </a-modal>
                </div>
              </div>
            </div>
            <button
              class="more base_white_color cursor"
              :disabled="records.length < 3 ? true : false"
              :class="
                type == 'clan'
                  ? 'clans'
                  : type == 'pay'
                  ? 'pay'
                  : type == 'talk'
                  ? 'talk'
                  : ''
              "
              @click="look_more(type, resultID, consumerId)"
            >
              {{
                records.length == 0 ||
                records.length == 1 ||
                records.length == 2
                  ? "暂无更多信息"
                  : type == "clan"
                  ? "查看更多合同记录"
                  : type == "pay"
                  ? "查看更多支付单"
                  : type == "talk"
                  ? "查看更多访谈记录"
                  : ""
              }}
            </button>
          </div>
        </div>
      </template>
    </TitleNav>
    <ebutton class="right_btn">
      <template #one>
        <button
          @click="gointerview(resultID, consumerId)"
          class="btn3 cursor base_white_color"
          v-if="type == 'talk'"
        >
          创建访谈
        </button>
        <button @click="goContract" class="btn1 cursor base_white_color" v-else>
          创建服务合同
        </button>
      </template>
      <template #two>
        <button
          @click="createUser()"
          class="btn4 cursor base_white_color"
          v-if="type == 'talk'"
        >
          创建家庭成员
        </button>
        <button @click="createUser" class="btn2 cursor base_white_color" v-else>
          创建客户信息
        </button>
      </template>
      <!-- <template v-slot:three> 这是插入到three插槽的内容 </template> -->
    </ebutton>
  </div>
</template>

<script>
import TitleNav from "../components/TitleNav"; // 上部导航 插槽
import ebutton from "../components/RightList"; // 右侧导航 插槽
import Clan from "../components/Clan"; //合同弹窗 组件

import interview from "../components/interview_con"; //访谈弹窗 组件

import {
  getLookInfo,
  getLookInfoClan,
  getLookInfoCity,
  getClanInfoCon,
  getClanDetail,
  getPayList,
  PostPay,
  gettalkList,
  PostPaySupplement,
} from "../api/api";

export default {
  name: "LookInfo",
  data() {
    return {
      info: {},
      clan: [],
      city: "",
      type: this.$route.query.type || "",
      records: [],
      visible: false,
      visible1: false,
      visible2: false,
      clanItem: {},
      consumerId: "",
      resultID: "",
      payMessage: null,
      interviewshow: false,
      interviewlist: {},
      onLine_value: 1,
      payWay: 1,
      pay_running: "",
      dateString: "",
      dateString2: "",
      dateString3: "",
      pay_name: "",
      pay_num: "",
      pay_bank: "",
      pay_bank_num: "",
      pay_bank_running: "",
      orderPayId: "", //支付里边id号
    };
  },
  components: { TitleNav, ebutton, Clan, interview },
  //   &pageNo=1&pageSize=2&accountId=00c49e7d16a391a0f730826ced549991
  created() {
    console.log(this.info.name, "================");
    const result = this.$route.query.id;
    this.resultID = this.$route.query.id;
    console.log(result, "----------=========");
    console.log(this.$route.query.type);

    console.log(this.$route.query.consumerId);
    this.consumerId = this.$route.query.consumerId;
    if (this.$route.query.type == "look") {
      getLookInfo({ id: result })
        .then((res) => {
          this.info = res.data.result; //本人信息  id
        })
        .then(() => {
          getLookInfoClan({ id: this.info.accountId }).then((res) => {
            console.log(res);
            this.clan = res.data.result; // 家庭成员信息
          });
        })
        .then(() => {
          getLookInfoCity({ accountId: result }).then((res) => {
            console.log(res, "pppppppppppppppp");
            this.city = res.data.message; //所在城市
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("请求信息出错!");
        });
    } else if (this.$route.query.type == "clan") {
      getLookInfo({ id: result })
        .then((res) => {
          this.info = res.data.result; //本人信息  id
        })
        .then(() => {
          getLookInfoCity({ accountId: result }).then((res) => {
            console.log(res, "pppppppppppppppp");
            this.city = res.data.message; //所在城市
          });
        })
        .then(() => {
          getClanInfoCon({
            pageNo: 1,
            pageSize: 5,
            accountId: result,
          }).then((res) => {
            // 合同记录
            console.log(res, "合同记录");
            this.records = res.data.result.records;
          });
        })
        .catch((err) => {
          console.log(err);
          this.$message.error("请求信息出错!");
        });
    } else if (this.$route.query.type == "pay") {
      getLookInfo({ id: result })
        .then((res) => {
          this.info = res.data.result; //本人信息  id
        })
        .then(() => {
          getLookInfoCity({ accountId: result }).then((res) => {
            console.log(res, "pppppppppppppppp");
            this.city = res.data.message; //所在城市
          });
        })
        .then(() => {
          getPayList({
            accountId: result,
          }).then((res) => {
            //支付列表
            console.log(res);
            this.records = res.data.result.records;
          });
        });
    } else if (this.$route.query.type == "talk") {
      getLookInfo({ id: result })
        .then((res) => {
          this.info = res.data.result; //本人信息  id
        })
        .then(() => {
          getLookInfoCity({ accountId: result }).then((res) => {
            console.log(res, "pppppppppppppppp");
            this.city = res.data.message; //所在城市
          });
        })

        .then(() => {
          gettalkList({ accountId: result }).then((res) => {
            console.log("============8============");
            console.log(res);
            this.records = res.data.result.records;
            console.log(this.interviewlist);
          });
        });
    }
  },
  methods: {
    goContract() {
      this.$router.push("/serveManage");
    },
    gointerview(resultID, consumerId) {
      this.$router.push({
        path: "/interview",
        query: {
          resultID,
          consumerId,
        },
      });
    },
    createUser() {
      this.$router.push("/infoOne");
    },
    handleChange(e) {
      console.log(e);
      this.pay_bank = e;
    },
    go(key) {
      switch (key) {
        case "home":
          this.$router.push("/home");
          break;
        case "customerList":
          this.$router.push("/customerList");
          break;
        default:
          break;
      }
    },
    async btnClick(item, storeName) {
      let res = await getClanDetail(item);
      console.log(res, "-----------");

      this.clanItem = res.data.result;
      this.clanItem.storeName = storeName;
      this.visible = true;
    },
    btnClickSupplement(e) {
      //调用手动补录功能
      this.visible2 = true;
      this.orderPayId = e;
    },
    handleOks_cancle() {
      this.visible2 = false;
    },
    btnClickPay(payNo) {
      //       const formData = new FormData();
      // console.log(formData.append("phoneNum", payNo));
      this.visible1 = true;

      PostPay(payNo).then((res) => {
        console.log(res, "000000000000000000000000");
        this.payMessage = res.data.message;
      });
    },
    interviewshow1(item) {
      console.log(item);
      this.interviewlist = item;
      console.log(this.interviewlist);
      this.interviewshow = true;
    },
    handleOks() {
      this.visible1 = false;
      this.visible2 = false;

      getPayList({
        //隐藏付款码页面时,重新请求付款状态
        accountId: this.resultID,
      }).then((res) => {
        //支付列表
        console.log(res);
        this.records = res.data.result.records;
      });
    },
    handleOk(e) {
      console.log(e);
      this.visible = false;
      this.interviewshow = false;
    },
    cancel() {
      this.visible = false;
    },
    change1(date, dateString) {
      console.log(date, dateString);
      this.dateString = dateString;
      this.$forceUpdate();
    },
    change2(date, dateString) {
      console.log(date, dateString);
      this.dateString2 = dateString;
      this.$forceUpdate();
    },
    change3(date, dateString) {
      console.log(date, dateString);
      this.dateString3 = dateString;
      this.$forceUpdate();
    },
    onLine(e) {
      console.log(e, "fangshi");
    },
    look_more(type, result, consumerId) {
      this.$router.push({
        path: "/record",
        query: {
          type,
          result,
          consumerId,
        },
      });
    },
    subOrder(e) {
      console.log(e, "12121");
      const date = new Date();
      const nowDate =
        date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
      console.log(nowDate);

      if (e == 1) {
        //在线
        console.log(this.pay_running);
        if (this.pay_running != "" && this.dateString != "") {
          PostPaySupplement({
            offlinePayType: 1,
            offlinePayChannel: this.payWay,
            offlinePaySerialNo: this.pay_running,
            orderPayId: this.orderPayId,
            offlinePayDate: this.dateString + " " + nowDate,
            flax: "11",
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("提交成功!");
              // setTimeout(() => {
              //   this.handleOks();
              // }, 500);
              this.$router.go(0);
            }
          });
        } else {
          this.$message.error("请将信息填写完整!");
        }
      }
      if (e == 2) {
        if (
          this.pay_name != "" &&
          this.pay_num != "" &&
          this.dateString2 != ""
        ) {
          PostPaySupplement({
            offlinePayType: 2,
            offlinePayPayee: this.pay_name,
            offlinePayAmount: this.pay_num,
            offlinePayDate: this.dateString2 + " " + nowDate,
            orderPayId: this.orderPayId,
            flax: "11",
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("提交成功!");
              // setTimeout(() => {
              //   this.handleOks();
              // }, 500);
              this.$router.go(0);
            }
          });
        } else {
          this.$message.error("请将信息填写完整!");
        }
      }
      if (e == 3) {
        if (
          this.pay_bank != "" &&
          this.pay_bank_num != "" &&
          this.pay_bank_running != "" &&
          this.dateString3 != ""
        ) {
          PostPaySupplement({
            offlinePayType: 3,
            offlinePayBank: this.pay_bank,
            offlinePayBankAmount: this.pay_bank_num,
            offlinePayBankSerialNo: this.pay_bank_running,
            offlinePayDate: this.dateString3 + " " + nowDate,
            orderPayId: this.orderPayId,
            flax: "11",
          }).then((res) => {
            if (res.data.success) {
              this.$message.success("提交成功!");
              // setTimeout(() => {
              //   this.handleOks();
              // }, 500);
              this.$router.go(0);
            }
          });
        } else {
          this.$message.error("请将信息填写完整!");
        }
      }
    },
  },
};
</script>

<style scoped>
.big_box {
  position: relative;
}

.right_btn {
  padding: 18px 0;
  font-size: 20px;
  /* float: right; */
  height: 100%;
  position: absolute;
  right: 0;
  top: 0px;
  z-index: 1;
}
.right_btn button {
  width: 172px;
  height: 64px;
  border-radius: 10px;
  margin-top: 32px;
  box-shadow: 0px 0px 16px 0px rgba(205, 210, 243, 0.4);
}
.btn1 {
  background: linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.btn2 {
  background: linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
.btn3 {
  background: linear-gradient(-90deg, #ffd284 0%, #ff9a32 100%);
}
.btn4 {
  background: linear-gradient(
    -90deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
}
/* ------------------------------------------------------------------------------------------ */

.pay_top {
  padding-bottom: 34px;
  border-bottom: 1px solid #e2e2e2;
}
.pay_top img {
  width: 24px;
  height: 24px;
  margin-right: 18px;
  vertical-align: sub;
}
.pay_top span {
  font-size: 22px;
}
.family_top {
  margin-top: 40px;
}
.base_info {
  font-size: 20px;
  display: flex;
  height: 50px;
  line-height: 50px;
  margin: 16px 0 16px 40px;
}
.base_info span:nth-child(1) {
  flex: 2;
}
.base_info span:nth-child(2) {
  flex: 4;
}
.family_name {
  display: flex;
  flex-wrap: wrap;
  margin-top: 34px;
  margin-bottom: 20px;
  margin-left: 30px;
}
.family_box {
  width: 324px;
  height: 137px;
  background: #ffffff;
  box-shadow: 0px 0px 18px 0px rgba(205, 210, 243, 0.185);
  border-radius: 8px;
  margin-left: 20px;
  overflow: hidden;
  font-size: 20px;
  text-align: center;
}

.name_tip {
  font-size: 16px;
  width: 127px;
  height: 35px;
  border-radius: 18px;
  margin-top: 18px;
  text-align: center;
  line-height: 35px;
  margin-left: -12px;
}
.backColor1 {
  background: #d8d8d8 linear-gradient(90deg, #fdb179 0%, #ff9158 100%);
}
.backColor2 {
  background: #d8d8d8 linear-gradient(90deg, #aee49e 0%, #82d3ea 100%);
}
.backColor3 {
  background: #d8d8d8 linear-gradient(90deg, #6eb2fe 0%, #6871f3 100%);
}
.clan_log {
  width: 1022px;
  height: 176px;
  background: #ffffff;
  box-shadow: 0px 0px 8px 1px #eee;
  border-radius: 2px;
  margin-top: 30px;
  margin-left: 40px;
}
.base_icon {
  width: 107px;
  height: 32px;
  border-radius: 0px 16px 16px 0px;
  position: relative;
  top: 20px;
  line-height: 32px;
  text-align: center;
  font-size: 14px;
}
.icon {
  background: linear-gradient(-90deg, #98dcc3 0%, #abe3a4 100%);
}
.pay_icon {
  background: linear-gradient(
    0deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
}
.talk_icon {
  background: linear-gradient(-90deg, #ffd284 0%, #ff9a32 100%);
}
.serve_info {
  position: relative;
  margin-left: 106px;
  font-size: 20px;
  line-height: 14px;
}
.serve_info div:nth-child(1) {
  /* flex: 3; */
  margin-top: 28px;
}
.serve_info div:nth-child(2) {
  position: absolute;
  right: 58px;
  top: 18px;
}
.more {
  width: 256px;
  height: 64px;
  border-radius: 12px;
  margin-left: 40px;
  margin-top: 50px;
  text-align: center;
  line-height: 64px;
  font-size: 20px;
  font-weight: 500;
}

.pay {
  background: linear-gradient(
    -90deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
}
.clans {
  background: linear-gradient(-90deg, #98dcc3 0%, #abe3a4 100%);
}
.talk {
  background: linear-gradient(-90deg, #ffd284 0%, #ff9a32 100%);
}

/* :deep(.clan_com .ant-modal-content .ant-modal-header #rcDialogTitle1) {
  font-weight: 600 !important;
  font-size: 22px !important;
} */

.payBtn1 {
  width: 98px;
  height: 38px;
  background: linear-gradient(270deg, #fe9a61 0%, #fdb680 100%);
  border-radius: 19px;
  font-size: 18px;
  text-align: center;
  line-height: 38px;
  margin-right: 20px;
}
.payBtn2 {
  width: 98px;
  height: 38px;
  background: linear-gradient(
    -90deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
  border-radius: 19px;
  font-size: 18px;
  text-align: center;
  line-height: 38px;
}

/* -------------------二维码----------------------- */
.code .title {
  font-size: 22px;
  text-align: center;
}
.code {
  position: relative;
}
.code .img {
  position: absolute;
  width: 50px;
  height: 50px;
  line-height: 58px;
  left: 0;
  top: -40px;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 9999;
  background-color: #ffffff;
  border-radius: 6px;
  border: 1px solid #7c7878;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}
.code img {
  width: 40px;
  height: 40px;
}
.talk_con p {
  padding: 80px 0;
  margin-top: -50px;
}
.right_icon {
  margin-top: 56px;
}

/* ------------------------------------------------- */
.mainPayTitle_con {
  position: relative;
  width: 413px;
  height: 52px;
  line-height: 52px;
  margin-top: 22px;
  border: 1px solid #ff9e65;
  border-radius: 7px;
  font-size: 18px;
  overflow: hidden;
  display: flex;
}
.mainPayTitle_con span {
  display: inline-block;
  width: 138px;
  text-align: center;
}
.mainPayTitle_con_backcolor {
  color: #fff;
  background: #ff9e65;
}
.mainPayTitle_con span:nth-child(1),
.mainPayTitle_con span:nth-child(3),
.mainPayTitle_con span:nth-child(5) {
  flex: 1;
}
.mainPayTitle_con span:nth-child(2),
.mainPayTitle_con span:nth-child(4) {
  flex: 0;
  font-size: 16px;
  color: rgba(206, 205, 205, 0.931);
}

.main_pay_body {
  padding-top: 20px;
}
.input_top {
  display: flex;
  margin-top: 22px;
  line-height: 52px;
}
.body_left {
  font-size: 20px;
  color: #666;
}
.pay_box {
  display: flex;
  margin-left: 78px;
}
.choose_pay {
  display: flex;
  position: relative;
  font-size: 20px;
  margin-right: 18px;
}
.choose_pay span:nth-child(1) {
  width: 20px;
  height: 20px;
  border: 1px solid var(--themeColor);
  border-radius: 50%;
  margin-top: 16px;
  margin-right: 6px;
}
.choose_pay .choose_weixin::before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 3px;
  top: 19px;
  width: 14px;
  height: 14px;
  background-color: var(--themeColor);
  border-radius: 50%;
}
.pay_running {
  margin-left: -20px;
  width: 372px;
  height: 48px;
  background: none;
  outline: none;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding-left: 16px;
  font-size: 20px;
}
.pay_running2 {
  margin-left: 0px;
}
.pay_running:hover {
  border: 1px solid var(--themeColor);
}
.pay_running:focus {
  box-shadow: 0px 0px 4px var(--themeColor);
  border: 1px solid var(--themeColor);
}
.pay_running::-webkit-input-placeholder,
:deep(.ant-input::-webkit-input-placeholder) {
  /* WebKit, Blink, Edge */
  color: #cacaca;
  font-size: 20px;
}
.pay_running:-moz-placeholder,
:deep(.ant-input::-moz-placeholder) {
  /* Mozilla Firefox 4 to 18 */
  color: #cacaca;
  font-size: 20px;
}
.pay_running::-moz-placeholder,
:deep(.ant-input::-moz-placeholder) {
  /* Mozilla Firefox 19+ */
  color: #cacaca;
  font-size: 20px;
}
.pay_running:-ms-input-placeholder,
:deep(.ant-input::-ms-input-placeholder) {
  /* Internet Explorer 10-11 */
  color: #cacaca;
  font-size: 20px;
}
:deep(.ant-calendar-picker) {
  height: 50px;
  width: 372px !important;
}

:deep(.ant-calendar-picker-input) {
  height: 48px;
  border-radius: 8px;
  font-size: 20px;
}

:deep(.ant-calendar-picker-input:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-input:focus) {
  border-color: var(--themeColor);
  box-shadow: 0px 0px 4px var(--themeColor);
}
.main_pay_bottom {
  margin-top: 70px;
  font-size: 20px;
}
.cancle {
  border-radius: 12px;
  width: 256px;
  margin-left: 10px;
  height: 64px;
  background: linear-gradient(-90deg, #98dcc3 0%, #abe3a4 100%);
}
.submit {
  width: 256px;
  margin-left: 24px;
  height: 64px;
  background: linear-gradient(
    -90deg,
    #6b97f9 0%,
    #ffffff 0%,
    #6b97f9 0%,
    #6daffd 100%
  );
  border-radius: 12px;
}
.pay_runningBank1 {
  margin-left: 0px;
}
/* -------------------------------收款银行输入框样式------------------------- */
:deep(.nurseDetail_con_item .ant-col) {
  width: 374px;
}
:deep(.ant-select-selection-placeholder) {
  padding-left: 8px !important;
  font-size: 20px;
  line-height: 46px !important;
}
:deep(.ant-select-selection-item) {
  height: 50px !important;
  line-height: 46px !important;
  padding-left: 10px !important;
  font-size: 20px;
}
:deep(.ant-select-selector) {
  height: 50px !important;
  border-radius: 8px !important;
  line-height: 50px !important;
  border: none !important;
  border: 1px solid #ccc !important;
}
:deep(.ant-select-selector:hover) {
  border: 1px solid var(--themeColor) !important;
}
:deep(.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector) {
  border: 1px solid var(--themeColor) !important;
  outline: 0;
  box-shadow: 0px 0px 4px var(--themeColor);
}
</style>
